<template>
  <div>
    <p v-if="post.date">
      <v-icon small class="mdi mdi-calendar"></v-icon>
      {{ post.date | toLocaleDateString }}
    </p>

    <div class="blog-markdown" v-html="markdown"></div>

    <p v-if="post.tags.length" class="mt-3">
      <v-icon class="mdi mdi-tag-multiple" style="vertical-align: middle;"></v-icon>
      {{ post.tags.toString() }}
    </p>
  </div>
</template>

<script>
import md from '@/markdown'

export default {
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  computed: {
    markdown() {
      return md.render(this.post.markdown)
    }
  },
  filters: {
    toLocaleDateString: d => d.toLocaleDateString()
  }
}
</script>

<style scoped>
@import url('~highlight.js/styles/vs2015.css');
.v-icon {
  vertical-align: inherit;
}
</style>
