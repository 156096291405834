import MarkDownIt from 'markdown-it'
import markdownItAttrs from 'markdown-it-attrs'

import hljs from 'highlight.js/lib/highlight'
import javascript from 'highlight.js/lib/languages/javascript'
hljs.registerLanguage('javascript', javascript)

const md = new MarkDownIt({
  highlight: function (str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return hljs.highlight(lang, str).value
      } catch (error) {
        console.error(error)
      }
    }

    return '' // use external default escaping
  }
})

md.use(markdownItAttrs)

export default md
